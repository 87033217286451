import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Mixpanel } from 'utils/mixpanel';

import { Header } from 'components/layout/BaseLayout/Header';
import { Accordion, AccordionItem } from 'components/ui/Accordion/Accordion';

import * as Styled from '../General.styles';

export const Faq: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.settings.faqPage' });
  const navigate = useNavigate();

  useEffect(() => {
    Mixpanel.track_pageview({ page: 'Settings - FAQ' });
  }, []);

  const FAQ_ACCORDION_ITEMS: AccordionItem[] = [
    {
      label: t('general'),
      variant: 'accordion',
      content: (
        <Accordion
          accordionItems={[
            {
              label: t('what'),
              content: t('whatDescription'),
            },
            {
              label: t('cost'),
              content: t('costDescription1'),
            },
            {
              label: t('duration'),
              content: t('durationDescription'),
            },
            {
              label: t('help'),
              content: t('helpDescription'),
            },
            {
              label: t('access'),
              content: t('accessDescription'),
            },
            {
              label: t('save'),
              content: (
                <>
                  <b>{t('saveForIphones')}</b>
                  {t('saveForIphonesDescription')}
                  <b>{t('saveForAndroids')}</b>
                  {t('saveForAndroidsDescription')}
                </>
              ),
            },
            {
              label: t('computer'),
              content: t('computerDescription'),
            },
          ]}
        />
      ),
    },
    {
      label: t('accountManage'),
      variant: 'accordion',
      content: (
        <Accordion
          accordionItems={[
            {
              label: t('forgotEmail'),
              content: t('forgotEmailDescription'),
            },
            {
              label: t('forgotPassword'),
              content: t('forgotPasswordDescription'),
            },
            {
              label: t('changeEmail'),
              content: t('changeEmailDescription'),
            },
          ]}
        />
      ),
    },
    {
      label: t('motivKitTitle'),
      variant: 'accordion',
      content: (
        <Accordion
          accordionItems={[
            {
              label: t('motivKit'),
              content: t('motivKitDescription'),
            },
            {
              label: t('getMotivKit'),
              content: t('getMotivKitDescription'),
            },
            {
              label: t('questionsKit'),
              content: t('questionsKitDescription'),
            },
          ]}
        />
      ),
    },
    {
      label: t('motivClinicalTeam'),
      variant: 'accordion',
      content: (
        <Accordion
          accordionItems={[
            {
              label: t('whoCoach'),
              content: t('whoCoachDescription'),
            },
            {
              label: t('contactcoach'),
              content: t('contactCoachDescription'),
            },
            {
              label: t('contactTherapist'),
              content: t('contactTherapistDescription'),
            },
            {
              label: t('connectTherapist'),
              content: t('connectTherapistDescription'),
            },
            {
              label: t('whenContact'),
              content: (
                <>
                  {t('whenContactDescription')}
                  <br />
                  <br />
                  {t('warning')}
                </>
              ),
            },
          ]}
        />
      ),
    },
    {
      label: t('emailsAndReminders'),
      variant: 'accordion',
      content: (
        <Accordion
          accordionItems={[
            {
              label: t('whatEmails'),
              content: t('whatEmailsDescription'),
            },
            {
              label: t('changeEmailNotifications'),
              content: t('changeEmailNotificationsDescription'),
            },
            {
              label: t('changeSMS'),
              content: t('changeSMSDescription'),
            },
          ]}
        />
      ),
    },
    {
      label: t('techSupp'),
      variant: 'accordion',
      content: (
        <Accordion
          accordionItems={[
            {
              label: t('issues'),
              content: t('issuesDescription'),
            },
          ]}
        />
      ),
    },
    {
      label: t('downloadReports'),
      variant: 'accordion',
      content: (
        <Accordion
          accordionItems={[
            {
              label: t('downloadBP'),
              content: t('downloadBPDescription'),
            },
            // {
            //   label: t('downloadActivity'),
            //   content: t('downloadActivityDescription'),
            // },
          ]}
        />
      ),
    },
    {
      label: t('endOfProgram'),
      variant: 'accordion',
      content: (
        <Accordion
          accordionItems={[
            {
              label: t('end'),
              content: t('endDescription'),
            },
            {
              label: t('unable'),
              content: t('unableDescription'),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <Styled.Container>
      <Styled.HeaderContainer>
        <Header variant="back" onBackClick={() => navigate(-1)} title={t('faq')} />
      </Styled.HeaderContainer>
      <Accordion accordionItems={FAQ_ACCORDION_ITEMS} />
    </Styled.Container>
  );
};
