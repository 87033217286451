import { useMutation, useQuery } from '@apollo/client';
import { CREATE_GOAL_HISTORY } from 'api/goal/goal.mutation';
import { GET_GOAL } from 'api/goal/goal.query';
import HealthwiseApiService from 'api/healthwise/healthwiseApiService';
import { HealthwiseArticleApiResponse } from 'api/healthwise/types';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { paths } from 'router/paths';
import { DATE_FORMAT } from 'utils/helpers';
import { findIncompleteSubgoal, isGoalCompleted } from 'utils/helpers/goals';
import { Mixpanel } from 'utils/mixpanel';

import { Header } from 'components/layout/BaseLayout/Header';
import { Button } from 'components/ui/Button/Button';
import { Typography } from 'components/ui/Typography/Typography';

import { HeaderContainer } from '../completeYourLessons/CompleteYourLessons.styles';
import * as Styled from './Lesson.styles';

export function getYouTubeEmbedUrl(url: string): string {
  try {
    const videoId = url.includes('youtu.be') ? url.split('/').pop() : new URL(url).searchParams.get('v');

    if (!videoId) throw new Error('Invalid YouTube URL');

    return `https://www.youtube.com/embed/${videoId}`;
  } catch (error) {
    console.error('Error parsing YouTube URL:', error);
    return '';
  }
}

const LESSON_URL_PREFIX = 'lesson';
//To replace href links from healthwise format to ours '/lesson/:goalId/:subgoalId/:lessonId'
const LINKS_REGEX_PATTERN = /(href=")(document)(\/.*?)["/]([^\s>]*)/g;
//To remove links with data-document-type decision point
const DECISION_POINT_REGEX_PATTERN =
  /<([a-zA-Z]+)[^>]*>(?:\s*<[^>]+data-document-type="DecisionPoint"[^>]*>.*?<\/a>\s*)+<\/\1>/gi;
const SECTIONS_TO_DISPLAY = ['HwSectionSpecialSection', 'HwCreditsSection', 'HwSectionFloat'];

// Add this new function to clean the HTML
const removeLearnMoreSection = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const learnMoreTitles = doc.querySelectorAll('h3.HwSubSectionTitle');
  learnMoreTitles.forEach((title) => {
    const titleText = title.textContent?.toLowerCase() || '';
    if (titleText.includes('learn more') || titleText.includes('explore more')) {
      const optionalContent = title.nextElementSibling;
      if (optionalContent && optionalContent.classList.contains('HwOptionalAll')) {
        title.remove();
        optionalContent.remove();
      }
    }
  });

  return doc.body.innerHTML;
};

interface CustomGoalData {
  type: string;
  title: string;
  link: string;
  transcript?: string;
}

export const Lesson: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.lessons' });
  const { subgoalId, goalId, lessonId } = useParams();
  const navigate = useNavigate();
  const { data: currentUserData } = useQuery(GET_CURRENT_USER_DATA);

  const [article, setArticle] = useState<HealthwiseArticleApiResponse | null>(null);
  const [isCompleted, setCompleted] = useState(false);
  const { data: goalData } = useQuery(GET_GOAL, {
    variables: { id: goalId, date: format(Date.now(), DATE_FORMAT) },
    fetchPolicy: 'network-only',
  });
  const { data: subgoalData } = useQuery(GET_GOAL, {
    variables: { id: subgoalId, date: format(Date.now(), DATE_FORMAT) },
    fetchPolicy: 'network-only',
  });
  const [createGoalHistory] = useMutation(CREATE_GOAL_HISTORY);

  const [customGoalData, setCustomGoalData] = useState<CustomGoalData | null>(null);
  const [videoError, setVideoError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchArticle() {
      if (lessonId) setArticle(await HealthwiseApiService.getArticle(lessonId));
    }
    if (lessonId === 'custom' && subgoalData?.goal) {
      const customGoalParts = subgoalData.goal.name?.split(/,(.+)/);
      try {
        const parsedCustomGoalData = JSON.parse(customGoalParts?.[1] || '{}');
        setCustomGoalData(parsedCustomGoalData);
      } catch (error) {
        console.error('Error parsing custom goal data:', error);
        setVideoError('Error loading custom content');
      }
    } else {
      fetchArticle();
    }
  }, [lessonId, subgoalData]);

  useEffect(() => {
    if (subgoalData?.goal) {
      setCompleted(isGoalCompleted(subgoalData?.goal));
    }
  }, [subgoalData?.goal]);

  const isSubgoalArticle = subgoalData?.goal?.name?.split(',')[0] === lessonId;

  const cleanHtml = (html: string) => {
    const LINKS_REPLACE_PATTERN = '$1' + '/' + LESSON_URL_PREFIX + '/' + goalId + '/' + subgoalId + '$3"';
    return DOMPurify.sanitize(html)
      .replace(LINKS_REGEX_PATTERN, LINKS_REPLACE_PATTERN)
      .replace(DECISION_POINT_REGEX_PATTERN, '');
  };

  const markAsCompleted = async () => {
    try {
      const isParentGoalCompleted = findIncompleteSubgoal(goalData?.goal)?.goalId === goalId;
      if (isParentGoalCompleted) {
        Mixpanel.track('Lessons_Goal');
      }
      if (article?.data?.type === 'video') {
        Mixpanel.track('Lessons_videos_viewed');
      } else {
        Mixpanel.track('Lessons_non_videos_viewed');
      }
      await createGoalHistory({
        variables: {
          user_id: currentUserData?.currentUser?.id,
          goal_id: subgoalId,
          completed_on: format(Date.now(), DATE_FORMAT),
          mark_parent_complete: isParentGoalCompleted,
        },
      });
      setCompleted(true);
      navigate(paths.completeYourLessons.replace(':goalId', goalId as string));
    } catch (error) {
      navigate(paths.homePage);
      throw new Error(`Completing lesson failed: ${error}`);
    }
  };

  const renderCustomContent = () => {
    if (!customGoalData) return null;
    return (
      <Styled.Content>
        <Styled.TextsContainer>
          <Typography weight={600} size={24}>
            {customGoalData.title}
          </Typography>
          <Styled.InnerHtml>
            {customGoalData.type === 'video' && (
              <>
                {videoError ? (
                  <Typography>{videoError}</Typography>
                ) : (
                  <iframe
                    width="100%"
                    height="315"
                    src={getYouTubeEmbedUrl(customGoalData.link)}
                    title={customGoalData.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    onError={() => setVideoError('Error loading video')}
                  ></iframe>
                )}
              </>
            )}
            {customGoalData.transcript && (
              <>
                <Typography variant="Headline2">{t('videoTranscript')}</Typography>
                <div dangerouslySetInnerHTML={{ __html: cleanHtml(customGoalData.transcript) }} />
              </>
            )}
          </Styled.InnerHtml>
        </Styled.TextsContainer>
      </Styled.Content>
    );
  };

  return (
    <Styled.PageContainer>
      <HeaderContainer>
        <Header
          onBackClick={() => {
            navigate(-1);
          }}
          variant="back"
          title={t('completeYourLessons')}
        />
      </HeaderContainer>
      {lessonId === 'custom'
        ? renderCustomContent()
        : article && (
            <>
              <Styled.Content>
                <Styled.TextsContainer>
                  <Typography weight={600} size={24}>
                    {article.data.title.consumer}
                  </Typography>
                  {article.data.resources.css.map(({ target }: { target: string }, index) => (
                    <link rel="stylesheet" href={target} key={index} />
                  ))}
                  <Styled.InnerHtml>
                    {article.data.topics
                      .filter(({ type }: { type: string }) => SECTIONS_TO_DISPLAY.includes(type))
                      .map(({ html }: { html: string }, idx) => {
                        const cleanedHtml = removeLearnMoreSection(html);
                        return <div key={idx} dangerouslySetInnerHTML={{ __html: cleanHtml(cleanedHtml) }} />;
                      })}
                    {article?.data?.transcript?.html && (
                      <>
                        <Typography variant="Headline2">{t('videoTranscript')}</Typography>
                        <div dangerouslySetInnerHTML={{ __html: cleanHtml(article.data.transcript.html) }} />
                      </>
                    )}
                  </Styled.InnerHtml>
                </Styled.TextsContainer>
              </Styled.Content>
              {isSubgoalArticle && (
                <Styled.ButtonContainer>
                  {isCompleted ? (
                    <Styled.CompletedButton>{t('completed')}</Styled.CompletedButton>
                  ) : (
                    <Button onClick={markAsCompleted}>{t('markAsCompleted')}</Button>
                  )}
                </Styled.ButtonContainer>
              )}
            </>
          )}
      {(isSubgoalArticle || lessonId === 'custom') && (
        <Styled.ButtonContainer>
          {isCompleted ? (
            <Styled.CompletedButton>{t('completed')}</Styled.CompletedButton>
          ) : (
            <Button onClick={markAsCompleted}>{t('markAsCompleted')}</Button>
          )}
        </Styled.ButtonContainer>
      )}
    </Styled.PageContainer>
  );
};
