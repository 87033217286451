import { useMutation, useQuery } from '@apollo/client';
import { CREATE_USER_LOCATION, UPDATE_USER_PHONE_NUMBER } from 'api/user/user.mutation';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { ReactComponent as Info } from 'assets/vectors/fi-rr-information.svg';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import { onEnterKey } from 'utils/helpers';
import { states } from 'utils/validators/address';
import { phoneNumberPattern, zipCodePattern } from 'utils/validators/patterns';
import * as Yup from 'yup';

import { Input } from 'components/form/Input/Input';
import { Typography } from 'components/ui/Typography/Typography';

import { KitModal } from './KitModal/KitModal';
import * as Styled from './MailingAddress.styles';

interface AddressFormValues {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  country: string;
  postcode: string;
  phoneNumber: string;
}

const initialFormValues = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  country: '',
  postcode: '',
  phoneNumber: '',
};

const DEFAULT_ADDRESS_NAME = 'Delivery';

export const MailingAddress: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.logIn.address' });
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const validationSchema = Yup.object().shape({
    addressLine1: Yup.string().required(t('fieldIsRequired').toString()),
    addressLine2: Yup.string(),
    city: Yup.string().required(t('fieldIsRequired').toString()),
    state: Yup.string()
      .test('is-state-correct', t('wrongState').toString(), function (value) {
        return value ? states.includes(value.toUpperCase()) : false;
      })
      .required(t('fieldIsRequired').toString()),
    postcode: Yup.string().matches(zipCodePattern, t('wrongFormatOfZipCode')).required(t('fieldIsRequired').toString()),
    phoneNumber: Yup.string()
      .matches(phoneNumberPattern, t('wrongFormatOfPhoneNumber'))
      .required(t('fieldIsRequired').toString()),
  });

  const { data } = useQuery(GET_CURRENT_USER_DATA);
  const [createLocation, { loading: createLocationLoading }] = useMutation(CREATE_USER_LOCATION);

  const [updateUserPhoneNumber, { loading: updateUserPhoneNumberLoading }] = useMutation(UPDATE_USER_PHONE_NUMBER);

  const onSubmit = async (values: AddressFormValues) => {
    try {
      await createLocation({
        variables: {
          user_id: data?.currentUser?.id,
          line1: values.addressLine1,
          line2: values.addressLine2,
          country: 'US',
          city: values.city,
          state: values.state.toUpperCase(),
          zip: values.postcode,
          name: DEFAULT_ADDRESS_NAME,
        },
      });
      await updateUserPhoneNumber({
        variables: {
          id: data?.currentUser?.id,
          phone_number: values.phoneNumber.replaceAll('-', ''),
        },
      });

      navigate(paths.homePage, { state: { onboarding: true } });
    } catch (_) {
      // TODO: add error handling
    }
  };

  return (
    <>
      <KitModal onClose={() => setIsModalOpen(false)} isOpen={isModalOpen} />
      <Styled.AddressStepContainer>
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={true}
          validateOnMount={true}
          onSubmit={onSubmit}
        >
          {({ isValid }) => (
            <>
              <Styled.Header>
                <Typography variant="Headline2" color="black.800" as="span">
                  {t('provideMailingAddress')}
                </Typography>{' '}
                <Typography
                  variant="Headline2"
                  color="blue.600"
                  as="span"
                  onClick={() => setIsModalOpen(true)}
                  onKeyDown={(e) => onEnterKey(e, () => setIsModalOpen(true))}
                  tabIndex={0}
                  aria-label="Open modal"
                  aria-haspopup="dialog"
                >
                  {t('motivKit')} <Styled.SIcon element={Info} color="blue.600" size={18} />
                </Typography>
              </Styled.Header>
              <Styled.SForm>
                <Input name="addressLine1" label={t('addressLine1')} />
                <Input name="addressLine2" label={t('addressLine2')} />
                <Input name="city" label={t('city')} />
                <Input name="state" minLength={2} maxLength={2} label={t('state')} />
                <Input name="postcode" label={t('postcode')} />
                <Input name="phoneNumber" label={t('phoneNumber')} />
                <Styled.SButton
                  variant="primary"
                  type="submit"
                  disabled={!isValid || createLocationLoading || updateUserPhoneNumberLoading}
                >
                  {t('next')}
                </Styled.SButton>
              </Styled.SForm>
            </>
          )}
        </Formik>
      </Styled.AddressStepContainer>
    </>
  );
};
