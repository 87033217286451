import { useQuery } from '@apollo/client';
import { GET_GOALS } from 'api/goal/goal.query';
import { ReactComponent as Star } from 'assets/vectors/fi-rr-glowing star.svg';
import { meanBy } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Mixpanel } from 'utils/mixpanel';
import { Maybe } from 'utils/types';
import usePrevious from 'utils/usePrevious';

import { Header } from 'components/layout/BaseLayout/Header';
import CircularProgressBar from 'components/ui/CircularProgressBar/CircularProgressBar';
import { Icon } from 'components/ui/Icon/Icon';
import LoadingPage from 'components/ui/LoadingPage/LoadingPage';
import TimeTabs, { TimeTabsIndex } from 'components/ui/Tabs/TimeTabs';
import { TimeSwitch, TimeSwitchType } from 'components/ui/TimeSwitch/TimeSwitch';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './MedicationsReadings.styles';
import { useGetMappedMedications } from './hooks';
import {
  MonthOption,
  filterGoalsByDateRange,
  formatDate,
  getIntervalForTab,
  getTimeRange,
  monthOptions,
  sortByDateDesc,
} from './utils';

export interface Answer {
  name: Maybe<string>;
  percentageCompleted: Maybe<number>;
  date: Maybe<string>;
  subgoalId: Maybe<string>;
}

export const MedicationsReadings: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.progress' });
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);
  const [currentSubTab, setCurrentSubTab] = useState<TimeSwitchType>('this');
  const [timeInterval, setTimeInterval] = useState(getTimeRange('thisWeek'));
  const [selectedMonth, setSelectedMonth] = useState<MonthOption | null>(monthOptions[new Date().getMonth()]);
  const currentTabHasChanged = usePrevious(currentTab) !== currentTab;
  const currentSubTabHasChanged = usePrevious(currentSubTab) !== currentSubTab;
  const { data, loading: goalsLoading } = useQuery(GET_GOALS, {
    variables: {
      keywords: 'medications',
    },
    fetchPolicy: 'network-only',
  });
  const filteredMedicationsAnswers = useGetMappedMedications(data);
  const sortedAnswers = useMemo(() => sortByDateDesc(filteredMedicationsAnswers), [filteredMedicationsAnswers]);
  const [filteredSortedAnswers, setFilteredSortedAnswers] = useState(sortedAnswers);
  const avgPercentage = !isEmpty(filteredSortedAnswers) ? meanBy(filteredSortedAnswers, 'percentageCompleted') : 0;

  const onTabChange = (tab: number) => {
    setCurrentTab(tab);
  };

  useEffect(() => {
    if (currentTabHasChanged || currentSubTabHasChanged) {
      setSelectedMonth(monthOptions[new Date().getMonth()]);
      setTimeInterval(getIntervalForTab(currentTab, currentSubTab));
      if (currentTab === TimeTabsIndex.month && selectedMonth) {
        setTimeInterval({
          startRange: selectedMonth?.dateRange.split(':')[0],
          endRange: selectedMonth?.dateRange.split(':')[1],
        });
      }
    }
  }, [currentTab, currentSubTab, selectedMonth, currentTabHasChanged, currentSubTabHasChanged]);

  useEffect(() => {
    setFilteredSortedAnswers(filterGoalsByDateRange(sortedAnswers, timeInterval));
  }, [timeInterval, sortedAnswers]);

  useEffect(() => {
    Mixpanel.track_pageview({ page: 'Progress - Medications' });
  }, []);

  if (goalsLoading) return <LoadingPage />;

  return (
    <>
      <Styled.HeaderContainer>
        <Header onBackClick={() => navigate(-1)} variant="back" title={t('medications')} />
      </Styled.HeaderContainer>
      <Styled.TimeTabsContainer>
        <TimeTabs onTabChange={onTabChange} />
      </Styled.TimeTabsContainer>
      <Styled.ProgressContainer>
        <CircularProgressBar
          progress={avgPercentage ? Math.floor(avgPercentage) : 0}
          size={64}
          startGradientColor="other.greenSea"
          endGradientColor="other.greenDark"
        >
          <Typography.Span size={20} color="black.800">
            {Math.floor(avgPercentage)}%
          </Typography.Span>
        </CircularProgressBar>
        <Styled.PregressDescriptionTabs>
          <Typography variant="SubHeadline1">{t('medicinesAdherence')}</Typography>
          {currentTab !== TimeTabsIndex.all_time && currentTab !== TimeTabsIndex.month && (
            <TimeSwitch
              time={currentTab === TimeTabsIndex.week ? 'week' : 'month'}
              onSelect={(value: TimeSwitchType) => setCurrentSubTab(value)}
              selected={currentSubTab}
            />
          )}
          {currentTab === TimeTabsIndex.month && (
            <Select
              options={monthOptions}
              defaultValue={selectedMonth}
              onChange={(newValue) => {
                if (newValue) {
                  setSelectedMonth(newValue);
                  setTimeInterval({
                    startRange: newValue?.dateRange.split(':')[0],
                    endRange: newValue?.dateRange.split(':')[1],
                  });
                }
              }}
              placeholder={t('Month')}
            />
          )}
        </Styled.PregressDescriptionTabs>
      </Styled.ProgressContainer>
      <Styled.ReadingsContainer>
        {isEmpty(filteredSortedAnswers) && !goalsLoading && (
          <Styled.NoDataContainer>
            <Typography variant="Body1Bold">{t('noDataTitle')}</Typography>
            <Typography variant="Helper1">{t('noDataSubtitle')}</Typography>
          </Styled.NoDataContainer>
        )}
        {!isEmpty(filteredSortedAnswers) && (
          <Styled.AnswerItemsContainer>
            {filteredSortedAnswers.map((answer, idx) => (
              <Styled.AnswerItem key={idx}>
                <Typography>{formatDate(answer?.date)}</Typography>
                <Styled.AnswerText weight={600}>
                  &#x2022; {answer?.name} {answer.percentageCompleted == 100 && <Icon element={Star} />}
                </Styled.AnswerText>
              </Styled.AnswerItem>
            ))}
          </Styled.AnswerItemsContainer>
        )}
      </Styled.ReadingsContainer>
    </>
  );
};
