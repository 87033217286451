import { Exercise } from 'api/exercises/exercises.model';
import { useVisualViewport } from 'hooks/useVisualViewport';
import React from 'react';

import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './ExerciseCard.styles';

interface Props {
  exerciseNumber: number;
  exercise: Exercise;
}

export const ExerciseCard: React.FC<Props> = ({ exercise }) => {
  const { visualViewportWidth } = useVisualViewport();

  const media = [exercise.video, ...exercise.image].filter((m) => m);
  // const tags = [
  //   exercise.duration,
  //   exercise.frequency,
  //   exercise.hold,
  //   exercise.repetition,
  //   exercise.rest,
  //   exercise.sets,
  //   exercise.tempo,
  //   exercise.weight,
  // ].filter((t) => t);

  return (
    <div key={exercise.code} id={exercise.code} style={{ marginBottom: '32px' }}>
      <Styled.Content>
        {/* <Typography.H2 size={20} weight={500}>
          {exerciseNumber}. {exercise.short_title}
        </Typography.H2>
        <Styled.TagsWrapper>
          {tags.map((tag, index) => (
            <Styled.Tag key={index}>
              <Typography size={10} color="blue.800" weight={600}>
                {tag}
              </Typography>
            </Styled.Tag>
          ))}
        </Styled.TagsWrapper> */}
        <Typography.Div size={16} weight={400} lineHeight={24} style={{ marginTop: '12px', marginLeft: '-16px' }}>
          {exercise.description}
        </Typography.Div>
      </Styled.Content>
      <Styled.MediaWrapper visualViewportWidth={visualViewportWidth || 320} style={{ maxHeight: '480px' }}>
        {media.map((mediaContent, index) =>
          mediaContent.includes('iframe') ? (
            <>
              <div>{`${new Date().toLocaleTimeString()}`}</div>
              <Styled.IframeWrapper key={index} dangerouslySetInnerHTML={{ __html: mediaContent }} />
            </>
          ) : (
            <div key={index}>
              <img src={mediaContent} alt="Exercise" style={{ width: 'auto' }} />
            </div>
          ),
        )}
      </Styled.MediaWrapper>
    </div>
  );
};
